import React, { useEffect, useState } from "react";
import { BiDollarCircle } from "react-icons/bi";
import "./UpgradeCard.css";
import axios from "axios";
import { ApiPaths } from "../../Config";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import { Data } from "../../Common/Data";
import ContractDetails from "../../Contracts/ContractDetails";
import GetChainId from "../../Common/GetChainId";
import getAddress from "../../Common/GetAddress";
const UpgradeCard = (props) => {
  const { BigInt } = window;
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [showOtp, setShowOtp] = useState(false);
  const [retopupAmount, setRetopupAmount] = useState("");
  const [subsAmountError, setSubsAmountError] = useState("");
  const [subsWalletsError, setSubsWalletsError] = useState("");
  const [subsPackageError, setSubsPackageError] = useState("");
  const [stakingPeriod, setStakingPeriod] = useState(0);

  const toastSuccess = (msg) => toast.success(msg);
  const toastFailed = (msg) => toast.error(msg);

  function resetSubError() {
    setSubsPackageError("");
    setSubsAmountError("");
    setSubsWalletsError("");
  }

  // -------------------------*******************************************-------------------------

  async function RetopupPackage() {
    console.log("props?.data?.pin_type", props?.data?.pin_type);
    resetSubError();
    if (stakingPeriod == "0") {
      setSubsPackageError("Please select package");
    }
    console.log("stakingPeriod rp:", stakingPeriod);
    if (
      parseInt(retopupAmount) < parseInt(props?.data?.pin_rate) ||
      parseInt(retopupAmount) > parseInt(props?.data?.max_amount)
    ) {
      setSubsAmountError(
        `Amount should be between ${props?.data?.pin_rate} to ${props?.data?.max_amount}`
      );
      Data.isDebug && console.log("Invalid Amount");
    }
    if (
      stakingPeriod != "0" &&
      retopupAmount >= parseInt(props?.data?.pin_rate) &&
      retopupAmount <= parseInt(props?.data?.max_amount)
    ) {
      setLoading(true);

      const isEligible = await checkEligible(retopupAmount);
      Data.isDebug && console.log("stakingPeriod:  ", stakingPeriod);
      if (isEligible == true) {
        let hash = await Topup(retopupAmount);
        if (hash) {
          console.log("hash", hash);
          setLoading(true);
          axios({
            method: "post",
            url: ApiPaths.retopup,
            data: {
              amount: retopupAmount,
              tx_hash: hash,
              selected_pin: props?.data?.pin_type,
              staking_period: stakingPeriod,
            },
            headers: {
              "Content-Type": "multipart/form-data",
              token: token,
            },
          })
            .then(function (response) {
              console.log("packages ", response);
              if (response?.data?.res == "error") {
                if (response?.data?.tokenStatus == "false") {
                  toastFailed("Token Expired Please Login");
                } else {
                  toastFailed(response?.data?.message);
                  setShowOtp(false);
                }
              } else {
                toastSuccess(response?.data?.message);
                setShowOtp(false);
              }
              setLoading(false);
            })
            .catch(function (response) {
              console.log(response);
              setLoading(false);
            });
        }
      } else {
        setLoading(false);
      }
    }
  }
  async function checkEligible(amount) {
    try {
      const response = await axios.post(
        ApiPaths.retopupEligiblity,
        {
          amount: amount,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      console.log("response", response);
      if (response?.data?.res == "success") {
        return true;
      } else {
        toastFailed(response?.data?.message);
        return false;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async function Topup(amount) {
    let increaseDone = await IncreaseAllowance(amount);
    if (increaseDone == true) {
      console.log({ amount });
      const walletAddress = await dashboardData?.profile?.[0]?.userAddress;
      const newAmount = BigInt(amount * 1e18);
      setLoading(true);
      try {
        const { ethereum } = window;
        if (ethereum) {
          let chain = await GetChainId();
          if (chain) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            const busdInstance = new ethers.Contract(
              ContractDetails.Contract,
              ContractDetails.ContractABI,
              signer
            );
            let inc = await busdInstance.stake(
              newAmount,
              "0x50966810A133cDf7083BDE254954A8D61041d09B",
              stakingPeriod * 365
            );
            const transactionData = await inc.wait();
            console.log("transactionData", transactionData);
            const hash = await transactionData.blockHash;
            return hash;
          }
          setLoading(false);
        } else {
          setLoading(false);
          console.log(ethereum);
        }
      } catch (error) {
        toastFailed("Transaction Failed");
        Data.isDebug && console.log("error", error);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }

  async function IncreaseAllowance(amount) {
    console.log({ amount });
    const newAmount = BigInt(amount * 1e18);
    setLoading(true);
    try {
      const { ethereum } = window;
      if (ethereum) {
        let chain = await GetChainId();
        if (chain) {
          const provider = new ethers.providers.Web3Provider(ethereum);
          const signer = provider.getSigner();
          const busdInstance = new ethers.Contract(
            ContractDetails.BUSD,
            ContractDetails.BUSD_ABI,
            signer
          );
          let inc = await busdInstance.increaseAllowance(
            ContractDetails.Contract,
            newAmount
          );
          await inc.wait();
          return true;
        }
        setLoading(false);
      } else {
        setLoading(false);
        console.log(ethereum);
      }
    } catch (error) {
      toastFailed("Transaction Failed");
      Data.isDebug && console.log("error", error);
      setLoading(false);
    }
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="upgradesCard" draggable={true}>
        <h1>{props?.data?.pin_type}</h1>
        <div>
          <p>Min Amount</p>
          <h5>${props?.data?.pin_rate}</h5>
        </div>
        <div className="upgradeCardLight">
          <p>Max Amount</p>
          <h5>${props?.data?.max_amount}</h5>
        </div>
        <div>
          <p>Daily ROI</p>
          <h5>{props?.data?.daily_roi}%</h5>
        </div>
        {/* <div className="upgradeCardLight">
          <p>PRICE</p>
          <h5>100 MUSD</h5>
        </div> */}
        <button
          className="simplePrimaryButton"
          onClick={() => setShowOtp(true)}
        >
          Buy & Stake
        </button>
      </div>
      {showOtp ? (
        <div className="otpSection">
          <div className="addfundDiv inputPrimary">
            <h1>{props?.data?.rank}</h1>
            {/* <div className="withdrawSelectWallet">
              <div
                className={
                  subsWallets == "fund_wallet"
                    ? "withdrawSelectWalletActive"
                    : ""
                }
                onClick={() => setSubsWallets("fund_wallet")}
              >
                <h5 className="mb-1">${props.fundWallet}</h5>
                <p className="mb-1">Fund Wallet</p>
              </div>
              <div
                className={
                  subsWallets == "package_wallet"
                    ? "withdrawSelectWalletActive"
                    : ""
                }
                onClick={() => setSubsWallets("package_wallet")}
              >
                <h5>${props.packageWallet}</h5>
                <p>Package Wallet</p>
              </div>
            </div> */}
            {/* <p className="errorMsg">{subsWalletsError}</p>
            <label htmlFor="Amount">User ID</label>
            <input
              min={1}
              required
              type="text"
              name=""
              id=""
              placeholder="Enter User ID"
              value={subsUserId}
              onChange={(e) => setSubsUserId(e.target.value)}
            />
            <p className="errorMsg">{subsUserIdError}</p> */}
            <label htmlFor="Amount">Amount</label>
            <div className="inputDivIcon">
              <input
                min={1}
                required
                type="text"
                name=""
                id=""
                placeholder="Enter Amount"
                value={retopupAmount}
                onChange={(e) => setRetopupAmount(e.target.value)}
              />
              <i>
                <BiDollarCircle />
              </i>
            </div>
            <p className="errorMsg">{subsAmountError}</p>

            <select
              value={stakingPeriod}
              onChange={(e) => setStakingPeriod(e.target.value)}
            >
              <option value="0">Staking Period.</option>
              <option value="2">2 Years</option>
              <option value="3">3 Years</option>
              <option value="5">5 Years</option>
            </select>

            <p className="errorMsg">{subsPackageError}</p>
            <p
              className=""
              style={{
                color: Data.colorPrimary,
                fontSize: "14px",
                margin: "5px 0",
              }}
            >
              Enter amount between ${props?.data?.pin_rate} to $
              {props?.data?.max_amount}
            </p>
            {loading ? (
              <div className="otpLoading"></div>
            ) : (
              <div className="d-flex" style={{ columnGap: "15px" }}>
                <button
                  className="btnSecondary mt-3 "
                  onClick={() => setShowOtp(false)}
                >
                  Cancel
                </button>
                <button className="btnPrimary mt-3" onClick={RetopupPackage}>
                  Proceed
                </button>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default UpgradeCard;
