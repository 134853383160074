// // import React, { useEffect, useState } from "react";
// // import { Row, Col } from "react-bootstrap";
// // import "./KYC.css";
// // import Loader from "../../Components/Loader/Loader";
// // import useAxiosHelper from "../../Common/AxiosHelper";
// // import { ApiPaths } from "../../Config/ApiPath";
// // import { toastFailed, toastSuccess } from "../../Config/BasicInfo";
// // import FileUpload from "./../../Components/FileUpload/FileUpload";
// // import DocumentUpload from "../../Components/DocumentUpload/DocumentUpload";

// // const KYC = () => {
// //   const [activeTab, setActiveTab] = useState("bank");
// //   const [loading, setLoading] = useState(false);
// //   const [accNo, setAccNo] = useState("");
// //   const [ifscCode, setIfscCode] = useState("");
// //   const [bankName, setBankName] = useState("");
// //   const [errors, setErrors] = useState({});
// //   const [address, setAddress] = useState("");
// //   const [idNo, setIdNo] = useState("");
// //   const [panNo, setPanNo] = useState("");
// //   const [selectedValue, setSelectedValue] = useState("");
// //   const [selectedBankType, setSelectedBankType] = useState("");
// //   const [bankType, setBankType] = useState("");
// //   const [fileName, setFileName] = useState("");
// //   const [holderName, setHolderName] = useState("");
// //   const [name, setName] = useState("");
// //   const { AxiosGet, AxiosPost } = useAxiosHelper();
// //   const [files, setFiles] = useState([]);
// //   const [previews, setPreviews] = useState([]);

// //   const handleFilesChange = (newFiles) => {
// //     setFiles(newFiles);
// //   };
// //   const handleBank = async () => {
// //     setLoading(true);

// //     try {
// //       const formData = new FormData();

// //       formData.append("accountNumber", accNo);
// //       formData.append("ifscCode", ifscCode);
// //       formData.append("bankName", bankName);
// //       formData.append("accountType", selectedBankType);
// //       formData.append("holderName", holderName);
// //       files.forEach((file) => {
// //         formData.append("document", file);
// //       });
// //       for (const [key, value] of formData.entries()) {
// //         console.log(`${key}: ${value}`);
// //       }

// //       const response = await AxiosPost(ApiPaths.getBankDetails, formData);
// //       console.log(response, "Response received");

// //       if (response) {
// //         toastSuccess(response?.message);

// //         setAccNo("");
// //         setBankName("");
// //         setIfscCode("");
// //         setSelectedBankType("");
// //         setHolderName("");
// //         setFiles([]);
// //       }
// //     } catch (e) {
// //       console.error("Error submitting bank details:", e);
// //       toastFailed(e?.response?.data?.message);
// //       setAccNo("");
// //       setBankName("");
// //       setIfscCode("");
// //       setSelectedBankType("");
// //       setHolderName("");
// //       setFiles([]);
// //     } finally {
// //       setLoading(false);
// //     }
// //   };

// //   const handlePan = async () => {
// //     setLoading(true);

// //     try {
// //       const formData = new FormData();

// //       formData.append("panNumber", panNo);
// //       files.forEach((file) => {
// //         formData.append("document", file);
// //       });
// //       for (const [key, value] of formData.entries()) {
// //         console.log(`${key}: ${value}`);
// //       }

// //       const res = await AxiosPost(ApiPaths.getPanCardDetails, formData);
// //       console.log(res?.message, "Response received");

// //       if (res) {
// //         toastSuccess(res?.message);

// //         setPanNo("");
// //         setFiles([]);
// //       }
// //     } catch (e) {
// //       console.error("Error submitting bank details:", e);
// //       toastFailed(e?.response?.data?.message);
// //       setPanNo("");
// //       setFiles([]);
// //     } finally {
// //       setLoading(false);
// //     }
// //   };
// //   const handleAddress = async () => {
// //     setLoading(true);

// //     try {
// //       const formData = new FormData();

// //       formData.append("idType", selectedValue);
// //       formData.append("idNumber", idNo);
// //       formData.append("name", name);
// //       formData.append("address", address);
// //       files.forEach((file) => {
// //         formData.append("documentFront", file);
// //       });
// //       files.forEach((file) => {
// //         formData.append("documentBack", file);
// //       });
// //       for (const [key, value] of formData.entries()) {
// //         console.log(`${key}: ${value}`);
// //       }

// //       const resp = await AxiosPost(ApiPaths.getAddressDetails, formData);
// //       console.log(resp, "Response received");

// //       if (resp) {
// //         toastSuccess(resp?.message);
// //         setSelectedValue("");
// //         setName("");
// //         setAddress("");
// //         setIdNo("");
// //         setFiles([]);
// //       }
// //     } catch (e) {
// //       console.error("Error submitting bank details:", e);
// //       toastFailed(e?.response?.data?.message);
// //       setSelectedValue("");
// //       setName("");
// //       setAddress("");
// //       setIdNo("");
// //       setFiles([]);
// //     } finally {
// //       setLoading(false);
// //     }
// //   };

// //   return (
// //     <section className="dashboard" style={{ paddingTop: "10px" }}>
// //       {loading && <Loader />}

// //       <Row className="mt-4">
// //         <Col lg="4" className="mb-2">
// //           <div className="tabButton">
// //             <button className="btnPrimary" onClick={() => setActiveTab("bank")}>
// //               Bank KYC
// //             </button>
// //             <button className="btnPrimary" onClick={() => setActiveTab("pan")}>
// //               Pan KYC
// //             </button>
// //             <button
// //               className="btnPrimary"
// //               onClick={() => setActiveTab("address")}
// //             >
// //               Address KYC
// //             </button>
// //           </div>
// //         </Col>
// //         <Col lg="6" className="mb-2">
// //           {activeTab === "bank" && (
// //             <div className="editProfile inputPrimary">
// //               <h3>Bank Details</h3>
// //               <label htmlFor="">Account no</label>
// //               <input
// //                 type="number"
// //                 placeholder="Account no"
// //                 value={accNo}
// //                 onChange={(e) => setAccNo(e.target.value)}
// //               />
// //               {errors.accNo && <p className="error">{errors.accNo}</p>}

// //               <label htmlFor="">IFSC Code</label>
// //               <input
// //                 placeholder="IFSC Code"
// //                 value={ifscCode}
// //                 onChange={(e) => setIfscCode(e.target.value)}
// //               />
// //               {errors.ifscCode && <p className="error">{errors.ifscCode}</p>}

// //               <label htmlFor="">Bank Name</label>
// //               <input
// //                 type="text"
// //                 placeholder="Bank Name"
// //                 value={bankName}
// //                 onChange={(e) => setBankName(e.target.value)}
// //               />
// //               {errors.bankName && <p className="error">{errors.bankName}</p>}

// //               <label htmlFor="">Bank Type</label>

// //               <select
// //                 id="mySelect"
// //                 value={selectedBankType}
// //                 onChange={(e) => setSelectedBankType(e.target.value)}
// //               >
// //                 <option>Choose Bank Type</option>
// //                 <option value="Saving">Saving</option>
// //                 <option value="Current">Current</option>
// //               </select>
// //               {errors.selectedBankType && (
// //                 <p className="error">{errors.selectedBankType}</p>
// //               )}

// //               <label htmlFor="">Holder Name</label>
// //               <input
// //                 type="text"
// //                 placeholder="Holder Name"
// //                 value={holderName}
// //                 onChange={(e) => setHolderName(e.target.value)}
// //               />
// //               {errors.holderName && (
// //                 <p className="error">{errors.holderName}</p>
// //               )}

// //               <label htmlFor="">Upload Document</label>
// //               <DocumentUpload onFilesChange={handleFilesChange} />
// //               <button className="btnPrimary" onClick={handleBank} type="submit">
// //                 Submit
// //               </button>
// //             </div>
// //           )}
// //           {activeTab === "pan" && (
// //             <div className="editProfile inputPrimary">
// //               <h3>Pan Card Details</h3>
// //               <label htmlFor="">Pan no</label>
// //               <input
// //                 placeholder="Pan no"
// //                 value={panNo}
// //                 onChange={(e) => setPanNo(e.target.value)}
// //               />
// //               <label htmlFor="">Upload Document</label>
// //               <DocumentUpload onFilesChange={handleFilesChange} />

// //               <button className="btnPrimary" onClick={handlePan} type="submit">
// //                 Submit
// //               </button>
// //             </div>
// //           )}
// //           {activeTab === "address" && (
// //             <div className="editProfile inputPrimary">
// //               <h3>Address Details</h3>
// //               <label htmlFor="">Name</label>
// //               <input
// //                 type="text"
// //                 placeholder="Name"
// //                 value={name}
// //                 onChange={(e) => setName(e.target.value)}
// //               />

// //               <label htmlFor="">Choose ID</label>

// //               <select
// //                 id="mySelect"
// //                 value={selectedValue}
// //                 onChange={(e) => setSelectedValue(e.target.value)}
// //               >
// //                 <option>Choose ID</option>
// //                 <option value="Adhar Card">Adhar Card</option>
// //                 <option value="Driving License">Driving License</option>
// //                 <option value="Passport">Passport</option>
// //               </select>
// //               <label htmlFor="">ID no</label>
// //               <input
// //                 type="text"
// //                 placeholder="Id no"
// //                 value={idNo}
// //                 onChange={(e) => setIdNo(e.target.value)}
// //               />
// //               <label htmlFor="">Address</label>
// //               <input
// //                 type="text"
// //                 placeholder="Address"
// //                 value={address}
// //                 onChange={(e) => {
// //                   setAddress(e.target.value);
// //                 }}
// //               />
// //               <label htmlFor="">Upload Front Image Document</label>
// //               <DocumentUpload onFilesChange={handleFilesChange} />
// //               <label htmlFor="">Upload Back Image Document</label>
// //               <DocumentUpload onFilesChange={handleFilesChange} />
// //               <button
// //                 className="btnPrimary"
// //                 type="submit"
// //                 onClick={handleAddress}
// //               >
// //                 Submit
// //               </button>
// //             </div>
// //           )}
// //         </Col>
// //       </Row>
// //     </section>
// //   );
// // };

// // export default KYC;






// import React, { useState } from "react";
// import { Row, Col } from "react-bootstrap";
// import { useDropzone } from "react-dropzone";
// import imageCompression from "browser-image-compression";
// import "./KYC.css";
// import Loader from "../../Components/Loader/Loader";
// // import useAxiosHelper from "../../Common/AxiosHelper";
// import { ApiPaths } from "../../Config";
// import { ToastContainer, toast } from "react-toastify";

// // import { toastFailed, toastSuccess } from "../../Config/BasicInfo";

// const KYC = () => {
//   const [activeTab, setActiveTab] = useState("bank");
//   const [loading, setLoading] = useState(false);
//   const [accNo, setAccNo] = useState("");
//   const [ifscCode, setIfscCode] = useState("");
//   const [bankName, setBankName] = useState("");
//   const [errors, setErrors] = useState({});
//   const [address, setAddress] = useState("");
//   const [idNo, setIdNo] = useState("");
//   const [panNo, setPanNo] = useState("");
//   const [selectedValue, setSelectedValue] = useState("");
//   const [selectedBankType, setSelectedBankType] = useState("");
//   const [bankType, setBankType] = useState("");
//   const [fileName, setFileName] = useState("");
//   const [holderName, setHolderName] = useState("");
//   const [name, setName] = useState("");
//   // const { AxiosPost } = useAxiosHelper();
//   const [files, setFiles] = useState([]);
//   const toastSuccess = (msg) => toast.success(msg);
//   const toastFailed = (msg) => toast.error(msg);

//   const [previews, setPreviews] = useState({
//     bank: [],
//     pan: [],
//     addressFront: [],
//     addressBack: []
//   });

//   const handleFilesChange = (newFiles, type) => {
//     setFiles(newFiles);
//     setPreviews((prevPreviews) => ({
//       ...prevPreviews,
//       [type]: newFiles.map((file) => URL.createObjectURL(file))
//     }));
//   };

//   const handleBank = async () => {
//     setLoading(true);
//     try {
//       const formData = new FormData();
//       formData.append("accountNumber", accNo);
//       formData.append("ifscCode", ifscCode);
//       formData.append("bankName", bankName);
//       formData.append("accountType", selectedBankType);
//       formData.append("holderName", holderName);
//       files.forEach((file) => {
//         formData.append("document", file);
//       });
//       const response = await AxiosPost(ApiPaths.getBankDetails, formData);
//       if (response) {
//         toastSuccess(response?.message);
//       }
//     } catch (e) {
//       console.error("Error submitting bank details:", e);
//       toastFailed(e?.response?.data?.message);
//     } finally {
//       setLoading(false);
//       setAccNo("");
//       setBankName("");
//       setIfscCode("");
//       setSelectedBankType("");
//       setHolderName("");
//       setFiles([]);
//       setPreviews((prevPreviews) => ({ ...prevPreviews, bank: [] }));
//     }
//   };

//   const handlePan = async () => {
//     setLoading(true);
//     try {
//       const formData = new FormData();
//       formData.append("panNumber", panNo);
//       files.forEach((file) => {
//         formData.append("document", file);
//       });
//       const res = await AxiosPost(ApiPaths.getPanCardDetails, formData);
//       if (res) {
//         toastSuccess(res?.message);
//       }
//     } catch (e) {
//       console.error("Error submitting PAN details:", e);
//       toastFailed(e?.response?.data?.message);
//     } finally {
//       setLoading(false);
//       setPanNo("");
//       setFiles([]);
//       setPreviews((prevPreviews) => ({ ...prevPreviews, pan: [] }));
//     }
//   };

//   const handleAddress = async () => {
//     setLoading(true);
//     try {
//       const formData = new FormData();
//       formData.append("idType", selectedValue);
//       formData.append("idNumber", idNo);
//       formData.append("name", name);
//       formData.append("address", address);
//       files.forEach((file) => {
//         formData.append("documentFront", file);
//       });
//       files.forEach((file) => {
//         formData.append("documentBack", file);
//       });
//       const resp = await AxiosPost(ApiPaths.getAddressDetails, formData);
//       if (resp) {
//         toastSuccess(resp?.message);
//       }
//     } catch (e) {
//       console.error("Error submitting address details:", e);
//       toastFailed(e?.response?.data?.message);
//     } finally {
//       setLoading(false);
//       setSelectedValue("");
//       setName("");
//       setAddress("");
//       setIdNo("");
//       setFiles([]);
//       setPreviews((prevPreviews) => ({ ...prevPreviews, addressFront: [], addressBack: [] }));
//     }
//   };

//   const onDrop = async (acceptedFiles, type) => {
//     const compressedFiles = await Promise.all(
//       acceptedFiles.map(async (file) => {
//         const options = {
//           maxSizeMB: 1,
//           maxWidthOrHeight: 1024,
//           useWebWorker: true,
//         };
//         try {
//           const compressedFile = await imageCompression(file, options);
//           return compressedFile;
//         } catch (err) {
//           console.error("Error compressing image:", err);
//           return file;
//         }
//       })
//     );
//     handleFilesChange(compressedFiles, type);
//   };

//   const { getRootProps: getBankRootProps, getInputProps: getBankInputProps } = useDropzone({ onDrop: (acceptedFiles) => onDrop(acceptedFiles, "bank") });
//   const { getRootProps: getPanRootProps, getInputProps: getPanInputProps } = useDropzone({ onDrop: (acceptedFiles) => onDrop(acceptedFiles, "pan") });
//   const { getRootProps: getAddressFrontRootProps, getInputProps: getAddressFrontInputProps } = useDropzone({ onDrop: (acceptedFiles) => onDrop(acceptedFiles, "addressFront") });
//   const { getRootProps: getAddressBackRootProps, getInputProps: getAddressBackInputProps } = useDropzone({ onDrop: (acceptedFiles) => onDrop(acceptedFiles, "addressBack") });

//   return (
//     <section className="dashboard" style={{ paddingTop: "10px" }}>
//       {loading && <Loader />}

//       <Row className="mt-4">
//         <Col lg="4" className="mb-2">
//           <div className="tabButton">
//             <button className="btnPrimary" onClick={() => setActiveTab("bank")}>
//               Bank KYC
//             </button>
//             <button className="btnPrimary" onClick={() => setActiveTab("pan")}>
//               Pan KYC
//             </button>
//             <button
//               className="btnPrimary"
//               onClick={() => setActiveTab("address")}
//             >
//               Address KYC
//             </button>
//           </div>
//         </Col>
//         <Col lg="6" className="mb-2">
//           {activeTab === "bank" && (
//             <div className="editProfile inputPrimary">
//               <h3>Bank Details</h3>
//               <label htmlFor="">Account no</label>
//               <input
//                 type="number"
//                 placeholder="Account no"
//                 value={accNo}
//                 onChange={(e) => setAccNo(e.target.value)}
//               />
//               {errors.accNo && <p className="error">{errors.accNo}</p>}

//               <label htmlFor="">IFSC Code</label>
//               <input
//                 placeholder="IFSC Code"
//                 value={ifscCode}
//                 onChange={(e) => setIfscCode(e.target.value)}
//               />
//               {errors.ifscCode && <p className="error">{errors.ifscCode}</p>}

//               <label htmlFor="">Bank Name</label>
//               <input
//                 type="text"
//                 placeholder="Bank Name"
//                 value={bankName}
//                 onChange={(e) => setBankName(e.target.value)}
//               />
//               {errors.bankName && <p className="error">{errors.bankName}</p>}

//               <label htmlFor="">Bank Type</label>

//               <select
//                 id="mySelect"
//                 value={selectedBankType}
//                 onChange={(e) => setSelectedBankType(e.target.value)}
//               >
//                 <option>Choose Bank Type</option>
//                 <option value="Saving">Saving</option>
//                 <option value="Current">Current</option>
//               </select>
//               {errors.selectedBankType && (
//                 <p className="error">{errors.selectedBankType}</p>
//               )}

//               <label htmlFor="">Holder Name</label>
//               <input
//                 type="text"
//                 placeholder="Holder Name"
//                 value={holderName}
//                 onChange={(e) => setHolderName(e.target.value)}
//               />
//               {errors.holderName && (
//                 <p className="error">{errors.holderName}</p>
//               )}

//               <label htmlFor="">Upload Document</label>
//               <div className="file-upload-container">
//                 <div {...getBankRootProps({ className: "dropzone" })}>
//                   <input {...getBankInputProps()} />
//                   <p>Drag 'n' drop bank document here, or click to select one</p>
//                 </div>
//                 {previews.bank.map((url, index) => (
//                   <img
//                     key={index}
//                     src={url}
//                     alt={`preview-${index}`}
//                     style={{ width: 100, height: 100, margin: 10 }}
//                   />
//                 ))}
//               </div>
//               <button className="btnPrimary" onClick={handleBank}>
//                 Submit
//               </button>
//             </div>
//           )}

//           {activeTab === "pan" && (
//             <div className="editProfile inputPrimary">
//               <h3>PAN Details</h3>
//               <label htmlFor="">Pan No</label>
//               <input
//                 type="text"
//                 placeholder="Pan No"
//                 value={panNo}
//                 onChange={(e) => setPanNo(e.target.value)}
//               />
//               {errors.panNo && <p className="error">{errors.panNo}</p>}

//               <label htmlFor="">Upload Document</label>
//               <div className="file-upload-container">
//                 <div {...getPanRootProps({ className: "dropzone" })}>
//                   <input {...getPanInputProps()} />
//                   <p>Drag 'n' drop PAN document here, or click to select one</p>
//                 </div>
//                 {previews.pan.map((url, index) => (
//                   <img
//                     key={index}
//                     src={url}
//                     alt={`preview-${index}`}
//                     style={{ width: 100, height: 100, margin: 10 }}
//                   />
//                 ))}
//               </div>
//               <button className="btnPrimary" onClick={handlePan}>
//                 Submit
//               </button>
//             </div>
//           )}

//           {activeTab === "address" && (
//             <div className="editProfile inputPrimary">
//               <h3>Address Details</h3>
//               <label htmlFor="">ID Type</label>
//               <select
//                 id="mySelect"
//                 value={selectedValue}
//                 onChange={(e) => setSelectedValue(e.target.value)}
//               >
//                 <option value="">Choose ID Type</option>
//                 <option value="Adhar Card">Aadhaar</option>
//                 <option value="Passport">Passport</option>
//                 <option value="Driver License">Driver License</option>
//               </select>
//               {errors.selectedValue && (
//                 <p className="error">{errors.selectedValue}</p>
//               )}

//               <label htmlFor="">ID Number</label>
//               <input
//                 type="text"
//                 placeholder="ID Number"
//                 value={idNo}
//                 onChange={(e) => setIdNo(e.target.value)}
//               />
//               {errors.idNo && <p className="error">{errors.idNo}</p>}

//               <label htmlFor="">Name</label>
//               <input
//                 type="text"
//                 placeholder="Name"
//                 value={name}
//                 onChange={(e) => setName(e.target.value)}
//               />
//               {errors.name && <p className="error">{errors.name}</p>}

//               <label htmlFor="">Address</label>
//               <input
//                 type="text"
//                 placeholder="Address"
//                 value={address}
//                 onChange={(e) => setAddress(e.target.value)}
//               />
//               {errors.address && <p className="error">{errors.address}</p>}

//               <label htmlFor="" className="mt-20">Upload Address Proof (Front)</label>
//               <div className="file-upload-container">
//                 <div {...getAddressFrontRootProps({ className: "dropzone" })}>
//                   <input {...getAddressFrontInputProps()} />
//                   <p>Drag 'n' drop address proof (front) here, or click to select one</p>
//                 </div>
//                 {previews.addressFront.map((url, index) => (
//                   <img
//                     key={index}
//                     src={url}
//                     alt={`preview-${index}`}
//                     style={{ width: 100, height: 100, margin: 10 }}
//                   />
//                 ))}
//               </div>

//               <label htmlFor="">Upload Address Proof (Back)</label>
//               <div className="file-upload-container">
//                 <div {...getAddressBackRootProps({ className: "dropzone" })}>
//                   <input {...getAddressBackInputProps()} />
//                   <p>Drag 'n' drop address proof (back) here, or click to select one</p>
//                 </div>
//                 {previews.addressBack.map((url, index) => (
//                   <img
//                     key={index}
//                     src={url}
//                     alt={`preview-${index}`}
//                     style={{ width: 100, height: 100, margin: 10 }}
//                   />
//                 ))}
//               </div>

//               <button className="btnPrimary" onClick={handleAddress}>
//                 Submit
//               </button>
//             </div>
//           )}
//         </Col>
//       </Row>
//     </section>
//   );
// };

// export default KYC;



import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import imageCompression from "browser-image-compression";
import axios from "axios";
import "./KYC.css";
import Loader from "../../Components/Loader/Loader";
import { ApiPaths } from "../../Config";
import { ToastContainer, toast } from "react-toastify";

const KYC = () => {
  const [activeTab, setActiveTab] = useState("bank");
  const [loading, setLoading] = useState(false);
  const [accNo, setAccNo] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [bankName, setBankName] = useState("");
  const [errors, setErrors] = useState({});
  const [address, setAddress] = useState("");
  const [idNo, setIdNo] = useState("");
  const [panNo, setPanNo] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedBankType, setSelectedBankType] = useState("");
  const [fileName, setFileName] = useState("");
  const [holderName, setHolderName] = useState("");
  const [name, setName] = useState("");
  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState({
    bank: [],
    pan: [],
    addressFront: [],
    addressBack: [],
  });

  const toastSuccess = (msg) => toast.success(msg);
  const toastFailed = (msg) => toast.error(msg);

  const handleFilesChange = (newFiles, type) => {
    setFiles(newFiles);
    setPreviews((prevPreviews) => ({
      ...prevPreviews,
      [type]: newFiles.map((file) => URL.createObjectURL(file)),
    }));
  };

  const submitDetails = async (url, formData, resetFields) => {
    setLoading(true);
    try {
      const response = await axios({
        method: "post",
        url: url,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response, "Response of Api")
      if (response?.data?.res =="success") {
        toastSuccess(response?.data?.message);
      }
      else{
        toastFailed(response?.data?.message);
      }
    } catch (e) {
      console.error("Error submitting details:", e);
      toastFailed(e?.response?.data?.message);
    } finally {
      setLoading(false);
      resetFields();
    }
  };



  const handleBank = async () => {
    let userId = localStorage.getItem("userId")

    const formData = new FormData();
    formData.append("u_id", userId);
    formData.append("account_no", accNo);
    formData.append("ifsc_code", ifscCode);
    formData.append("bank_name", bankName);
    formData.append("bank_type", selectedBankType);
    formData.append("account_holder_name", holderName);
    files.forEach((file) => {
      formData.append("bank_img", file);
    });
    console.log("Bank FormData:");
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }
    await submitDetails(ApiPaths.bankDetails, formData, () => {
      setAccNo("");
      setBankName("");
      setIfscCode("");
      setSelectedBankType("");
      setHolderName("");
      setFiles([]);
      setPreviews((prevPreviews) => ({ ...prevPreviews, bank: [] }));

    });
  };

  const handlePan = async () => {
    let userId = localStorage.getItem("userId")
    const formData = new FormData();
    formData.append("u_id", userId);
    formData.append("pan_no", panNo);
    files.forEach((file) => {
      formData.append("pan_image", file);
    });
    // console.log(formData, ".......................")
    console.log("Pan FormData:");
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    await submitDetails(ApiPaths.panDetails, formData, () => {
      setPanNo("");
      setFiles([]);
      setPreviews((prevPreviews) => ({ ...prevPreviews, pan: [] }));
    });
  };

  const handleAddress = async () => {
    let userId = localStorage.getItem("userId")
    console.log(userId, ".......................")

    const formData = new FormData();
    formData.append("u_id", userId);
    formData.append("attached_doc", selectedValue);
    formData.append("aadhaarNumber", idNo);
    formData.append("name", name);
    formData.append("address", address);
    files.forEach((file) => {
      formData.append("front_image", file);
    });
    files.forEach((file) => {
      formData.append("back_image", file);
    });
    console.log("Address FormData:");
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    await submitDetails(ApiPaths.adhaarDetails, formData, () => {
      setSelectedValue("");
      setName("");
      setAddress("");
      setIdNo("");
      setFiles([]);
      setPreviews((prevPreviews) => ({
        ...prevPreviews,
        addressFront: [],
        addressBack: [],
      }));
    });
  };

  const onDrop = async (acceptedFiles, type) => {
    const compressedFiles = await Promise.all(
      acceptedFiles.map(async (file) => {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1024,
          useWebWorker: true,
        };
        try {
          const compressedFile = await imageCompression(file, options);
          return compressedFile;
        } catch (err) {
          console.error("Error compressing image:", err);
          return file;
        }
      })
    );
    handleFilesChange(compressedFiles, type);
  };

  const { getRootProps: getBankRootProps, getInputProps: getBankInputProps } = useDropzone({
    onDrop: (acceptedFiles) => onDrop(acceptedFiles, "bank"),
  });
  const { getRootProps: getPanRootProps, getInputProps: getPanInputProps } = useDropzone({
    onDrop: (acceptedFiles) => onDrop(acceptedFiles, "pan"),
  });
  const { getRootProps: getAddressFrontRootProps, getInputProps: getAddressFrontInputProps } = useDropzone({
    onDrop: (acceptedFiles) => onDrop(acceptedFiles, "addressFront"),
  });
  const { getRootProps: getAddressBackRootProps, getInputProps: getAddressBackInputProps } = useDropzone({
    onDrop: (acceptedFiles) => onDrop(acceptedFiles, "addressBack"),
  });

  return (
    <section className="dashboard" style={{ paddingTop: "10px" }}>
      {loading && <Loader />}

      <Row className="mt-4">
        <Col lg="4" className="mb-2">
          <div className="tabButton">
            <button className="btnPrimary" onClick={() => setActiveTab("bank")}>
              Bank KYC
            </button>
            <button className="btnPrimary" onClick={() => setActiveTab("pan")}>
              Pan KYC
            </button>
            <button className="btnPrimary" onClick={() => setActiveTab("address")}>
              Address KYC
            </button>
          </div>
        </Col>
        <Col lg="6" className="mb-2">
          {activeTab === "bank" && (
            <div className="editProfile inputPrimary">
              <h3>Bank Details</h3>
              {/* Bank form fields */}
              <label htmlFor="">Account no</label>
              <input
                type="number"
                placeholder="Account no"
                value={accNo}
                onChange={(e) => setAccNo(e.target.value)}
              />
              {errors.accNo && <p className="error">{errors.accNo}</p>}

              <label htmlFor="">IFSC Code</label>
              <input
                placeholder="IFSC Code"
                value={ifscCode}
                onChange={(e) => setIfscCode(e.target.value)}
              />
              {errors.ifscCode && <p className="error">{errors.ifscCode}</p>}

              <label htmlFor="">Bank Name</label>
              <input
                type="text"
                placeholder="Bank Name"
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
              />
              {errors.bankName && <p className="error">{errors.bankName}</p>}

              <label htmlFor="">Bank Type</label>
              <select
                id="mySelect"
                value={selectedBankType}
                onChange={(e) => setSelectedBankType(e.target.value)}
              >
                <option>Choose Bank Type</option>
                <option value="Saving">Saving</option>
                <option value="Current">Current</option>
              </select>
              {errors.selectedBankType && <p className="error">{errors.selectedBankType}</p>}

              <label htmlFor="">Holder Name</label>
              <input
                type="text"
                placeholder="Holder Name"
                value={holderName}
                onChange={(e) => setHolderName(e.target.value)}
              />
              {errors.holderName && <p className="error">{errors.holderName}</p>}

              <label htmlFor="">Upload Document</label>
              <div className="file-upload-container">
                <div {...getBankRootProps({ className: "dropzone" })}>
                  <input {...getBankInputProps()} />
                  <p>Drag 'n' drop bank document here, or click to select one</p>
                </div>
                {previews.bank.map((url, index) => (
                  <img
                    key={index}
                    src={url}
                    alt={`Bank Document Preview ${index + 1}`}
                    className="preview-image"
                  />
                ))}
              </div>

              <button
                className="btnPrimary mt-4"
                type="submit"
                disabled={loading}
                onClick={handleBank}
              >
                Save
              </button>
            </div>
          )}
          {activeTab === "pan" && (
            <div className="editProfile inputPrimary">
              <h3>Pan Card Details</h3>
              {/* Pan form fields */}
              <label htmlFor="">Pan No</label>
              <input
                type="text"
                placeholder="Pan No"
                value={panNo}
                onChange={(e) => setPanNo(e.target.value)}
              />
              {errors.panNo && <p className="error">{errors.panNo}</p>}

              <label htmlFor="">Upload Document</label>
              <div className="file-upload-container">
                <div {...getPanRootProps({ className: "dropzone" })}>
                  <input {...getPanInputProps()} />
                  <p>Drag 'n' drop Pan document here, or click to select one</p>
                </div>
                {previews.pan.map((url, index) => (
                  <img
                    key={index}
                    src={url}
                    alt={`Pan Document Preview ${index + 1}`}
                    className="preview-image"
                  />
                ))}
              </div>

              <button
                className="btnPrimary mt-4"
                type="submit"
                disabled={loading}
                onClick={handlePan}
              >
                Save
              </button>
            </div>
          )}
          {activeTab === "address" && (
            <div className="editProfile inputPrimary">
              <h3>Address KYC</h3>
              {/* Address form fields */}
              <label htmlFor="">ID Type</label>
              <select
                id="mySelect"
                value={selectedValue}
                onChange={(e) => setSelectedValue(e.target.value)}
              >
                <option>Choose ID Type</option>
                <option value="Adhar Card">Adhar Card</option>
                <option value="Voter ID">Voter ID</option>
                <option value="Driving Licence">Driving Licence</option>
              </select>
              {errors.selectedValue && <p className="error">{errors.selectedValue}</p>}

              <label htmlFor="">ID Number</label>
              <input
                type="text"
                placeholder="ID Number"
                value={idNo}
                onChange={(e) => setIdNo(e.target.value)}
              />
              {errors.idNo && <p className="error">{errors.idNo}</p>}

              <label htmlFor="">Name</label>
              <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {errors.name && <p className="error">{errors.name}</p>}

              <label htmlFor="">Address</label>
              <input
                type="text"
                placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              {errors.address && <p className="error">{errors.address}</p>}

              <label htmlFor="">Upload Address Front Document</label>
              <div className="file-upload-container">
                <div {...getAddressFrontRootProps({ className: "dropzone" })}>
                  <input {...getAddressFrontInputProps()} />
                  <p>Drag 'n' drop Address front document here, or click to select one</p>
                </div>
                {previews.addressFront.map((url, index) => (
                  <img
                    key={index}
                    src={url}
                    alt={`Address Front Document Preview ${index + 1}`}
                    className="preview-image"
                  />
                ))}
              </div>

              <label htmlFor="">Upload Address Back Document</label>
              <div className="file-upload-container">
                <div {...getAddressBackRootProps({ className: "dropzone" })}>
                  <input {...getAddressBackInputProps()} />
                  <p>Drag 'n' drop Address back document here, or click to select one</p>
                </div>
                {previews.addressBack.map((url, index) => (
                  <img
                    key={index}
                    src={url}
                    alt={`Address Back Document Preview ${index + 1}`}
                    className="preview-image"
                  />
                ))}
              </div>

              <button
                className="btnPrimary mt-4"
                type="submit"
                disabled={loading}
                onClick={handleAddress}
              >
                Save
              </button>
            </div>
          )}
        </Col>
      </Row>
    </section>
  );
};

export default KYC;
