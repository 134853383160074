import React, { useEffect, useState } from "react";
import "./Withdraw.css";
import axios from "axios";
import { ApiPaths } from "../../Config";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../Components/Loader/Loader";
import { Link } from "react-router-dom";
import { Data } from "../../Common/Data";
import { useSelector } from "react-redux";
const Withdraw = () => {
  const acc = useSelector((state) => state.account.value);
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState("");
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [otpLoading, setOtpLoading] = useState(false);
  const [withdrawalWallet, setWithdrawalWallet] = useState("main_wallet");
  const [withdrawalAddress, setWithdrawalAddress] = useState("BEP20");
  const [withdrawalAddressError, setWithdrawalAddressError] = useState("");
  const [qrData, setQrData] = useState();
  const [authCode, setAuthCode] = useState("");
  const [authCodeError, setAuthCodeError] = useState("");
  const token = localStorage.getItem('token');
  const [accountInfo, setAccountInfo] = useState([]);

  useEffect(() => {
    fetch2FAData();
    FetchData();
  }, []);

  const toastSuccess = (msg) => toast.success(msg);
  const toastFailed = (msg) => toast.error(msg);



  function fetch2FAData() {
    setLoading(true);
    axios({
      method: "post",
      url: ApiPaths.fetchQr,

      headers: {
        "Content-Type": "multipart/form-data",
        token: token
      },
    })
      .then(function (response) {
        Data.isDebug && console.log("fetch qr code", response);
        setQrData(response?.data)
        setLoading(false);
      })
      .catch(function (response) {
        Data.isDebug && console.log(response);
        setLoading(false);
      });
  }







  function resetError() {
    setWithdrawalAddressError("");
    setAmountError("");
    setAuthCodeError("")
  }
  function FetchData() {
    setLoading(true);
    let userId = localStorage.getItem("userId");
    axios({
      method: "post",
      url: ApiPaths.dashboard,
      data: {
        u_id: userId,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        // console.log(response);
        setDashboardData(response?.data);
        setAccountInfo(response?.data?.account_info?.[0]);
        localStorage.setItem("dashboardData", JSON.stringify(response?.data));
        setLoading(false);
      })
      .catch(function (response) {
        // console.log(response);
        setLoading(false);
      });
  }
  function WithdrawOTP() {
    let withdrawAdd = false;
    resetError();
    let user_Id = localStorage.getItem("userId");

    setLoading(true);
    if (amount > 0 && withdrawAdd) {
      axios({
        method: "post",
        url: ApiPaths.transferFundOtp,
        data: {
          u_code: user_Id,
          otp_type: "withdrawal",
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (response) {
          // console.log(response);
          if (response?.data?.res == "success") {
            FetchData();
            toastSuccess(response?.data?.message);
            setShowOtp(true);
          } else {
            const errorAmountWithoutTags = response?.data?.error_amount.replace(
              /<\/?p>/g,
              ""
            );
            if (response?.data?.errorAmountWithoutTags.length > 0) {
              toastFailed(errorAmountWithoutTags.replace(/<\/?p>/g, ""));
            } else {
              toastFailed(response?.data?.message);
            }
          }
          setLoading(false);
        })
        .catch(function (response) {
          // console.log(response);
          toastFailed("Something went wrong");
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }


  function WithdrawFunc() {
    let user_Id = localStorage.getItem("userId");
    resetError();

    if (withdrawalWallet == "1") {
      setWithdrawalAddressError("Select withdrawal wallet");
      showOtp(false);
    }
    if (!amount > 0) {
      setAmountError("Invalid Amount");
      showOtp(false);
    }
    if (authCode.length !== 6) {
      setAuthCodeError("Invalid Code");
    }
    setOtpLoading(true);

    if (amount > 0 && withdrawalWallet !== "1" && authCode.length == 6) {
      setLoading(true)
      axios({
        method: "post",
        url: ApiPaths.withdraw,
        data: {
          u_id: user_Id,
          selected_wallet: withdrawalWallet,
          amount: amount,
          otp_input: authCode,
          withdraw_type: withdrawalAddress,
          payment_address: acc,
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (response) {
          Data.isDebug && console.log("withdraw", response);
          if (response?.data?.res == "success") {
            fetch2FAData();
            FetchData();
            setAuthCode("");
            setAmount("");
            toastSuccess(response?.data?.message);
            setLoading(false)
          } else {
            setAuthCode("");
            toastFailed(response?.data?.message.replace(/<\/?p>/g, ""));
          }
          setLoading(false);
        })
        .catch(function (response) {
          // console.log(response);
          toastFailed("Something went wrong");
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }

  return (
    <section className="dashboard">
      {loading ? <Loader /> : null}
      {showOtp ? (
        <div className="otpSection">
          <div className="otpContainer">
            <h1>OTP</h1>
            <p>OTP sent to your registered email address</p>
            <input
              type="text"
              maxLength={6}
              size={6}
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            <p className="errorMsg">{otpError}</p>
            {otpLoading ? (
              <div className="otpLoading"></div>
            ) : (
              <div>
                <button
                  className="btnSecondary"
                  onClick={() => (setOtp(""), setShowOtp(false))}
                >
                  Cancel
                </button>
                <button className="btnPrimary" onClick={WithdrawFunc}>
                  Submit
                </button>
              </div>
            )}
          </div>
        </div>
      ) : null}
      <div className="addfundDiv inputPrimary">
        <h1>Withdraw Wallet</h1>
        {/* <div className='addfundDivFundWallet'>
                    <p className='mb-1'>Main Wallet</p>
                    <p className='mb-1'>${parseFloat(dashboardData?.wallets?.main_wallet).toFixed(2)}</p>
                </div>
                <div className='addfundDivFundWallet'>
                    <p>Package Wallet</p>
                    <p>${parseFloat(dashboardData?.wallets?.package_wallet).toFixed(2)}</p>
                </div> */}
        <div className="withdrawSelectWallet">
          <div className="main_wallet">
            <h5 className="mb-1">
              ${parseFloat(dashboardData?.wallets?.main_wallet).toFixed(2)}
            </h5>
            <p className="mb-1">Withdraw Wallet</p>
          </div>
        </div>
        {
          qrData?.is_2fa_enabled == "1" ?
            <div>

              <label htmlFor="Amount">Withdrawal Address</label>
              <select
                value={withdrawalAddress}
                onChange={(e) => setWithdrawalAddress(e.target.value)}
              >
                <option value="BEP20">BEP20</option>
              </select>
              <p className="errorMsg">{withdrawalAddressError}</p>
              <label htmlFor="Amount">Amount</label>
              <input
                min={1}
                required
                type="number"
                placeholder="Enter Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
              <p className="errorMsg">{amountError}</p>
              <label htmlFor="Amount">2FA Code</label>
              <input
                required
                type="text"
                placeholder="2FA Code"
                value={authCode}
                onChange={(e) => e.target.value?.length <= 6 ? setAuthCode(e.target.value) : ""}
              />
              <p className="errorMsg">{authCodeError}</p>
              {
                acc?.length > 0 ?
                  <button className="btnPrimary mt-3" onClick={WithdrawFunc}>
                    Proceed
                  </button>
                  :
                  <p style={{ color: "red", fontSize: "12px", margin: "10px 0 0 0 " }}>Wallet Not Connected</p>
              }
            </div>
            :
            <Link to='/dashboard/2fa' className="btnPrimary">Set up 2FA</Link>
        }
      </div>
    </section>
  );
};

export default Withdraw;
