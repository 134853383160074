import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import "./Slot.css";
import { Row, Col } from "react-bootstrap";
import { FaTelegramPlane } from "react-icons/fa";
import PDFImage from "./../../Images/pdfPage.png";
import Team from "./../../Images/team.png";
import { ApiPaths } from "../../Config";
import axios from "axios";
import Loader from "../../Components/Loader/Loader";
import InfoPage from "../../Components/InfoPage/InfoPage";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Data } from "../../Common/Data";
import Leaf from "./../../Images/leafs.png";
import CoinLiveRate from "../../Common/CoinLiveRate";
import StakedCoin from "../../Common/Staked";
import { useSelector } from "react-redux";
import Timer from "../../Components/Timer";
const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [myRank, setMyRank] = useState();
  const [incomeInfo, setIncomeInfo] = useState(false);
  const [incomeHeading, setIncomeHeading] = useState(false);
  const [infoData, setInfoData] = useState();
  const [coinLiveRate, setCoinLiveRate] = useState();
  const [stakedCoinRate, setStakedCoinRate] = useState();
  const [planData, setplanData] = useState([]);
  const token = localStorage.getItem("token");

  let myArray = Array.from({ length: 8 });
  const acc = useSelector((state) => state.account.value);
  let x = 0;
  useEffect(() => {
    if (x === 0) {
      EffectiveDate();
      getCoinRate();
      checkData();
      x = 1;
    }
  }, []);
  useEffect(() => {
    getStakedCoinRate();
  }, [acc]);

  const toastSuccess = (msg) => toast.success(msg);
  const toastFailed = (msg) => toast.error(msg);
  function IncomeInfoState(newstate) {
    setIncomeInfo(newstate);
  }

  function InfoPageData(heading, data) {
    // Data.isDebug && console.log(heading);
    setIncomeHeading(heading);
    setInfoData(data);
    setIncomeInfo(true);
  }

  function checkData() {
    let jsondata = localStorage.getItem("dashboardData");
    const data = JSON.parse(jsondata);
    // Data.isDebug && console.log("data", data);
    if (data) {
      setDashboardData(data);
      setMyRank(data?.profile?.[0]?.my_rank);
      FetchData();
    } else {
      FetchData(true);
    }
  }
  function EffectiveDate() {
    axios({
      method: "get",
      url: ApiPaths.getPlans,
      headers: {
        "Content-Type": "multipart/form-data",
        token: token,
      },
    }).then(function (response) {
      // Data.isDebug && console.log("plan data", response);
      setplanData(response?.data);
    });
  }

  function FetchData(checkload) {
    if (checkload) {
      setLoading(true);
    }
    let userId = localStorage.getItem("userId");
    // Data.isDebug && console.log("user id", userId);
    axios({
      method: "post",
      url: ApiPaths.dashboard,
      data: {
        u_id: userId,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        // console.log("......................", response);
        setDashboardData(response?.data);
        Data.isDebug &&
          // console.log("first", response?.data?.profile?.[0]?.my_rank);
          setMyRank(response?.data?.profile?.[0]?.my_rank);
        localStorage.setItem("dashboardData", JSON.stringify(response?.data));
        setLoading(false);
      })
      .catch(function (response) {
        // Data.isDebug && console.log(response);
        setLoading(false);
      });
  }
  async function getStakedCoinRate() {
    let stakeRate = await StakedCoin(acc);
    // console.log("stakeRate", stakeRate);
    setStakedCoinRate(stakeRate);
  }

  async function getCoinRate() {
    // console.log("acc", acc);
    let rate = await CoinLiveRate(acc);
    setCoinLiveRate(rate);
  }
  return (
    <>
      {loading ? <Loader /> : null}
      {incomeInfo ? (
        <InfoPage
          updateState={IncomeInfoState}
          heading={incomeHeading}
          data={infoData}
        />
      ) : null}
      <section className="dashboard">
        <h1 className="textHeading">Dashboard</h1>
        <Row>
          <Col lg="6">
            <Row style={{ height: "100%" }}>
              <Col md="6" className="mb">
                <div className="dashboardMainAccountCard d-flex flex-column justify-content-between">
                  <div>
                    <div className="aboutDetails d-flex justify-content-between w-100 coinRateAndStake">
                      <p>OnikCoin Live Rate</p>
                      <h5>${parseFloat(coinLiveRate ?? "0").toFixed(10)}</h5>
                    </div>
                    <div className="aboutDetails d-flex justify-content-between w-100 coinRateAndStake">
                      <p>Staked OnikCoin</p>
                      {acc?.length > 0 ? (
                        <h5>{parseFloat(stakedCoinRate ?? "0").toFixed(5)}</h5>
                      ) : (
                        <h5 style={{ color: "red" }}>Not Connected</h5>
                      )}
                    </div>
                    <div className="aboutDetails d-flex justify-content-between w-100 coinRateAndStake">
                      <p>Withdrawal Wallet</p>
                      <p>
                        ${" "}
                        {parseFloat(
                          dashboardData?.wallets?.main_wallet
                        ).toFixed(2)}
                      </p>
                    </div>
                  </div>
                  <div className="metaDiv">
                    <div>
                      <img src={Data.logoIcon} alt="" />
                    </div>
                  </div>
                  <div>
                    <div className="d-flex gap-2">
                      <Link
                        to="plans"
                        className="flex-1"
                        style={{ width: "100%" }}
                      >
                        <button className="btnPrimary">Investment</button>
                      </Link>
                      <Link
                        to="withdraw"
                        className="flex-1"
                        style={{ width: "100%" }}
                      >
                        <button className="btnPrimary">Withdraw</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="6" className="mb">
                <div className="dashboardRankCard">
                  <h6 style={{ color: "#ddb965" }}>Current Status</h6>

                  <div className="d-flex align-items-center justify-content-between mb-2"></div>
                  <Link className="leafImg" to="reward">
                    <img src={Leaf}></img>
                  </Link>
                  <div className="aboutDetails">
                    <p>Activation Status</p>
                    <h5>
                      {planData?.effectiveDate && (
                        <Timer time={dashboardData?.effectiveDate} />
                      )}
                    </h5>
                  </div>
                  <div className="aboutDetails">
                    <p>Staking Package</p>
                    <h5>{dashboardData?.profile?.[0]?.my_package ?? "0"}</h5>
                  </div>
                  <div className="aboutDetails">
                    <p>Staking Amount</p>
                    <h5>
                      $
                      {parseFloat(dashboardData?.package_amount ?? "0").toFixed(
                        2
                      )}
                    </h5>
                  </div>
                  <div className="aboutDetails">
                    <p>My Rank</p>
                    <h5
                      style={{
                        color: "var(--colorPrimary)",
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                    >
                      {dashboardData?.profile?.[0]?.my_rank ?? "0"}
                    </h5>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            lg="6"
            className="gap-2 d-flex flex-column justify-content-between"
          >
            <Row>
              {dashboardData?.incomes != null
                ? dashboardData?.incomes?.map((x, i) => {
                    return (
                      <Col md="6" className="mb-2">
                        <div className="dashboardIncomeCard">
                          <div className="dashboardData">
                            <div>
                              <h5 className="dashboardCardHeading">
                                {x?.name}
                              </h5>

                              {x?.name === "Rank & Rewards" ? (
                                <h1>
                                  {dashboardData?.profile?.[0]?.my_rank ?? "0"}
                                </h1>
                              ) : (
                                <h1>
                                  $ {parseFloat(x?.value).toFixed(2) ?? "0"}
                                </h1>
                              )}
                            </div>
                            <div>
                              <img
                                src={x?.icons}
                                style={{ height: 30, width: 30 }}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })
                : myArray?.map((x, i) => {
                    return (
                      <Col md="6" className="mb-2">
                        <div className="dashboardIncomeCard">
                          <div>
                            <div>
                              <h5 className="dashboardCardHeading">
                                ................
                              </h5>
                            </div>
                          </div>
                          <h1>0.00</h1>
                          <div></div>
                        </div>
                      </Col>
                    );
                  })}
            </Row>
          </Col>
        </Row>
        <section className="mt-3">
          <Row>
            <Col lg="4" className="mb-2">
              <div className="wallets">
                <div className="walletsIconDiv">
                  <div className="walletsImg">
                    {" "}
                    <img src={Team} alt="" />
                    <h1>Team</h1>
                  </div>
                </div>
                <div className="walletsData">
                  <p> Direct Team Members (Active) </p>
                  <p>{dashboardData?.teams?.active_directs}</p>
                </div>
                <div className="walletsData">
                  <p> Direct Team Members (Inactive) </p>
                  <p>{dashboardData?.teams?.inactive_directs}</p>
                </div>
                <div className="walletsData">
                  <p> Level Team Members (Active)</p>
                  <p>
                    {dashboardData?.teams?.active_gen -
                      dashboardData?.teams?.active_directs}
                  </p>
                </div>
                <div className="walletsData">
                  <p> Level Team Members (Inactive)</p>
                  <p>
                    {dashboardData?.teams?.total_gen -
                      dashboardData?.teams?.active_gen -
                      dashboardData?.teams?.inactive_directs}
                  </p>
                </div>
                <div className="walletsData">
                  <p> Total Team Members </p>
                  <p>{dashboardData?.teams?.total_gen}</p>
                </div>
              </div>
            </Col>
          </Row>
        </section>
        <section className="news">
          <h1 className="textHeadingWithMargin">News</h1>
          <Row>
            <Col md="8" className="mb">
              <div className="newsDiv">
                <Row>
                  <Col lg="6">
                    <div className="dashboardNewsCard">
                      <img
                        src={dashboardData?.latest_news?.urlToImage}
                        alt="img.jpg"
                      />
                      <p>{dashboardData?.latest_news?.publishedAt}</p>
                      <h3>{dashboardData?.latest_news?.title}</h3>
                      <h5>{dashboardData?.latest_news?.description}</h5>
                      <Link to="news">
                        <button className="btnSecondary mt-4 newsMobile">
                          More news
                        </button>
                      </Link>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="dashboardNewsCardRight">
                      <h5 className="dashboardCardHeading">Latest News</h5>
                      <h4>{dashboardData?.latest_news?.description}</h4>
                      <h3>{dashboardData?.latest_news?.publishedAt}</h3>
                      <Link to="news">
                        <button className="btnSecondary">More news</button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md="4">
              <div className="presentation">
                <div>
                  <h5 className="dashboardCardHeading">Business Plan</h5>
                  <div>
                    <img src={PDFImage} alt="pdf" />
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <button
                    className="btnSecondary"
                    onClick={() => window.open(dashboardData?.pdf, "_blank")}
                  >
                    View
                  </button>
                  <a
                    href={dashboardData?.pdf}
                    download={true}
                    className="btnSecondary text-center"
                  >
                    Download
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </section>
        <h1 className="textHeadingWithMargin">Telegram</h1>
        <div className="telegramBotDiv">
          <Row className="align-items-center">
            <Col md="6">
              <div id="telegramBotDivText">
                <i>
                  <FaTelegramPlane />
                </i>
                <div>
                  <h5>{Data.projectName} Official Community</h5>
                  <p>
                    Join the OnikCoin Offical Community for exclusive updates
                    and discussions!
                  </p>
                </div>
              </div>
            </Col>
            <Col md="6">
              <button
                onClick={() =>
                  window.open(dashboardData?.telegram_link, "_blank")
                }
                className="btnPrimary"
              >
                Join Now
              </button>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
