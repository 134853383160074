import React, { useEffect, useState } from "react";
import "./Reward.css";
import { ApiPaths } from "../../Config";
import axios from "axios";
import { GiDiamondTrophy } from "react-icons/gi";
import Loader from "../../Components/Loader/Loader";
import RewardCard from "../../Components/RewardCard/RewardCard";
import { Col, Row } from "react-bootstrap";
import { Data } from "../../Common/Data";
const Reward = () => {
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  useEffect(() => {
    checkData();
  }, []);

  function checkData() {
    let jsondata = localStorage.getItem("rewardData");
    const data = JSON.parse(jsondata);
    // console.log("data", data)
    if (data) {
      setDashboardData(data);
      FetchData();
    } else {
      FetchData(true);
    }
  }
  function FetchData(load) {
    if (load) {
      setLoading(true);
    }
    let userId = localStorage.getItem("userId");
    axios({
      method: "post",
      url: ApiPaths.reward,
      data: {
        u_id: userId,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        // console.log("reward", response)
        setDashboardData(response?.data?.data);
        localStorage.setItem(
          "rewardData",
          JSON.stringify(response?.data?.data)
        );
        setLoading(false);
      })
      .catch(function (response) {
        setLoading(false);
      });
  }
  return (
    <section className="dashboard">
      {loading ? <Loader /> : null}
      <h5
        id="developmentBonusHeading"
        className="d-flex align-items-center justify-content-center"
      >
        {" "}
        <i className="d-flex">
          <GiDiamondTrophy />
        </i>
        Rewards
      </h5>

      <Row style={{ rowGap: "20px" }} className="mt-4">
        {dashboardData &&
          dashboardData?.map((x, i) => {
            return (
              <Col md="4" lg="3" sm="6" xs="12">
                <RewardCard
                  rank={x?.rank}
                  income={x?.rank_income}
                  community={x?.Current_Month_Community}
                  status={x?.status}
                  condition={x?.rank_reward}
                  image={Data.logoIcon}
                />
              </Col>
            );
          })}
      </Row>
    </section>
  );
};

export default Reward;
