import React, { useEffect, useState } from "react";
import "./Google2FA.css";
import Logo from "./../../Images/logo.png";
import Google from "./../../Images/logo.png";
import { toast } from "react-toastify";
import QR from "./../../Images/qr.png";
import { ApiPaths } from "../../Config";
import axios from "axios";
import { Data } from "./../../Common/Data";
import Loader from "./../../Components/Loader/Loader";
import Check from "./../../Images/checkGolden.png";
const Google2FA = () => {
  const [loading, setLoading] = useState(false);
  const [qrData, setQrData] = useState();
  const [authCode, setAuthCode] = useState("");
  const [disable2faCode, setDisable2faCode] = useState("");
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchData();
  }, []);

  const toastSuccess = (msg) => toast.success(msg);
  const toastFailed = (msg) => toast.error(msg);

  function fetchData() {
    setLoading(true);
    axios({
      method: "post",
      url: ApiPaths.fetchQr,

      headers: {
        "Content-Type": "multipart/form-data",
        token: token,
      },
    })
      .then(function (response) {
        Data.isDebug && console.log("fetch qr code", response);
        setQrData(response?.data);
        setLoading(false);
        resetCodes();
      })
      .catch(function (response) {
        Data.isDebug && console.log(response);
        setLoading(false);
      });
  }
  function disable2FA() {
    console.log("token", token);
    setLoading(true);
    axios({
      method: "post",
      url: ApiPaths.disable2fa,
      data: {
        otp: disable2faCode,
      },
      headers: {
        "Content-Type": "multipart/form-data",
        token: token,
      },
    })
      .then(function (response) {
        Data.isDebug && console.log("disable2FA", response);
        if (response?.data?.res == "success") {
          fetchData();
          toastSuccess(response?.data?.message);
          resetCodes();
        } else {
          toastFailed(response?.data?.message);
          setLoading(false);
          resetCodes();
        }
        setLoading(false);
      })
      .catch(function (response) {
        Data.isDebug && console.log(response);
        setLoading(false);
      });
  }

  function veifyCode() {
    setLoading(true);
    axios({
      method: "post",
      url: ApiPaths.verifyAuthCode,
      data: {
        token_data: authCode,
      },
      headers: {
        "Content-Type": "multipart/form-data",
        token: token,
      },
    })
      .then(function (response) {
        Data.isDebug && console.log(response);
        if (response?.data?.res == "success") {
          toastSuccess(response?.data?.message);
          fetchData();
          resetCodes();
        } else {
          toastFailed(response?.data?.message);
          resetCodes();
        }
        setLoading(false);
      })
      .catch(function (response) {
        Data.isDebug && console.log(response);
        setLoading(false);
      });
  }

  function resetCodes() {
    setAuthCode("");
    setDisable2faCode("");
  }

  return (
    <div>
      {loading ? <Loader /> : null}
      {qrData?.is_2fa_enabled == 0 ? (
        <section className="googleAuth inputPrimary dashboard">
          <div className="googleAuthDiv">
            <img id="authLogo" src={Logo} alt="logo.png" />
            <h1>Set Up Two-Factor Authentication</h1>
            <p>Download an authenticator app</p>
            <p>
              <span>Android,IOS and Blackberry</span> - Google Authenticator
            </p>
            <p>
              <span>Windows Phone</span> - Authenticator
            </p>
            <p>Scan this code with the app</p>
            <img
              draggable={false}
              id="authQR"
              src={qrData?.qr_code_url}
              alt="qr.png"
            />
            {/* <img draggable={false} id='authQR' src={QR} alt="qr.png" /> */}
            <h5>{qrData?.key}</h5>
            <p className="m-0">Enter the 6-digit code generated by the app</p>
            <label>Code</label>
            <input
              type="text"
              placeholder="Code"
              value={authCode}
              onChange={(e) =>
                e.target.value.length <= 6 ? setAuthCode(e.target.value) : ""
              }
            />
            <button className="btnPrimary mt-3" onClick={veifyCode}>
              VERIFY CODE
            </button>
          </div>
        </section>
      ) : qrData?.is_2fa_enabled == 1 ? (
        <div className="dashboard">
          <div className="googleEnable">
            <h5>Congratulations! Your account is secure</h5>
            <img
              src={Check}
              alt="google.png"
              style={{
                width: "100px",
                display: "block",
                margin: "0px auto 20px auto",
              }}
            />
            <h4>Status: Active</h4>
            <p>
              Enter the otp from Google Authenticator to disable the Two Factor
              Authentication. This is not advisable,since this will reduce your
              account security
            </p>
            <input
              type="tel"
              placeholder="code"
              min={6}
              max={6}
              value={disable2faCode}
              onChange={(e) =>
                e.target.value.length <= 6
                  ? setDisable2faCode(e.target.value)
                  : ""
              }
            />
            <button onClick={disable2FA}>Disable</button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Google2FA;
