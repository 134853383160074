import { ethers } from "ethers";
import ContractDetails from "../Contracts/ContractDetails";
import { Data } from "./Data";

export default async function StakedCoin(id) {
  try {
    const provider = new ethers.providers.JsonRpcProvider(Data.providerLink);
    const signer = provider.getSigner(ContractDetails.Contract);
    const contractInstance = new ethers.Contract(
      ContractDetails.Contract,
      ContractDetails.ContractABI,
      signer
    ); 
    console.log("contractInstance",contractInstance)
    const stakeCoin = await contractInstance.getUserStakes(id);
    let stakedCoinCount = 0;
    for(let i=0;i<stakeCoin?.length;i++){
      stakedCoinCount += parseFloat(stakeCoin?.[i]?.stakedToken);
    }
    // Data.isDebug && console.log("Contract data:", parseFloat(stakeCoin / 100));
    return stakedCoinCount/1e18;
  } catch (error) {
    console.error("Error fetching contract data:", error);
    throw error; // Rethrow the error to be caught by the caller
  }
}
