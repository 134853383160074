const BUSD_ABI = require("../Contracts/BUSD_ABI.json");
const ContractABI = require("../Contracts/ContractABI.json");

const arr = {
  BUSD: "0x55d398326f99059fF775485246999027B3197955",
  BUSD_ABI: BUSD_ABI,
  Contract: "0x73E9f5d3F5705B490A8a4B3710038511eBa6E42d",
  ContractABI: ContractABI,
};

const ContractDetails = arr;
module.exports = ContractDetails;
