import React, { useEffect, useState } from "react";
import "./Plans.css";
import { GiDeadEye } from "react-icons/gi";
import { RxLapTimer } from "react-icons/rx";
import { Col, Row } from "react-bootstrap";
import UpgradeCard from "../../Components/UpgradeCard/UpgradeCard";
import axios from "axios";
import { ApiPaths } from "../../Config";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../Components/Loader/Loader";
import Timer from "../../Components/Timer";
import { Data } from "../../Common/Data";
import ContractDetails from "../../Contracts/ContractDetails";
import { ethers } from "ethers";
import GetChainId from "../../Common/GetChainId";
import getAddress from "../../Common/GetAddress";
import GetTokenBalance from "../../Common/GetTokenBalance";
import ConnectButton from "../../Components/ConnectButton";
import { useSelector } from "react-redux";
// import { isDisabled } from "@testing-library/user-event/dist/utils";

const Plans = () => {
  const acc = useSelector((state) => state.account.value);
  const token = localStorage.getItem("token");
  const { BigInt } = window;
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [packagesData, setPackagesData] = useState([]);
  const [aiUserId, setAiUserId] = useState("");
  const [aiUserIdError, setAiUserIdError] = useState("");
  const [activationpackage, setActivationPackage] = useState("1");
  const [packageError, setPackageError] = useState("");
  const [subsUserId, setSubsUserId] = useState("");
  const [planData, setplanData] = useState([]);
  const [sponsorLoading, setSponsorLoading] = useState(false);
  const [checkSponsorExist, setCheckSponsorExist] = useState([]);
  const [tokenBalance, setTokenBalance] = useState(0);

  useEffect(() => {
    FetchContractData();
  }, [acc]);

  function FetchContractData() {
    try {
      FetchPlanData();
      getBalance(acc);
      checkData();
      FetchPackages();
    } catch (e) {
      console.log("error ", e);
    }
  }

  const toastSuccess = (msg) => toast.success(msg);
  const toastFailed = (msg) => toast.error(msg);

  function checkData() {
    let jsondata = localStorage.getItem("dashboardData");
    const data = JSON.parse(jsondata);
    if (data) {
      setDashboardData(data);
      setAiUserId(data?.profile?.[0]?.username);
      onUserStoppedTyping(data?.profile?.[0]?.username);
      setSubsUserId(data?.profile?.[0]?.username);
      FetchData();
    } else {
      FetchData();
    }
  }
  async function getBalance() {
    let bal = await GetTokenBalance(acc);
    setTokenBalance(bal);
  }
  function FetchPackages() {
    // setLoading(true);
    let userId = localStorage.getItem("userId");
    axios({
      method: "post",
      url: ApiPaths.packages,
      data: {
        u_id: userId,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        Data.isDebug && console.log("Res", response);
        setPackagesData(response?.data);

        localStorage.setItem("packages", JSON.stringify(response?.data));
        setLoading(false);
      })
      .catch(function (response) {
        setLoading(false);
      });
  }
  function FetchData() {
    // setLoading(true);
    let userId = localStorage.getItem("userId");
    axios({
      method: "post",
      url: ApiPaths.dashboard,
      data: {
        u_id: userId,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        Data.isDebug && console.log("dashboard data ", response);
        setDashboardData(response?.data);
        setAiUserId(response?.data?.profile?.[0]?.username);
        onUserStoppedTyping(response?.data?.profile?.[0]?.username);
        setSubsUserId(response?.data?.profile?.[0]?.username);
        localStorage.setItem("dashboardData", JSON.stringify(response?.data));
        setLoading(false);
      })
      .catch(function (response) {
        setLoading(false);
      });
  }
  function FetchPlanData() {
    setLoading(true);
    axios({
      method: "get",
      url: ApiPaths.getPlans,
      headers: {
        "Content-Type": "multipart/form-data",
        token: token,
      },
    })
      .then(function (response) {
        Data.isDebug && console.log("plan data", response);
        setplanData(response?.data);
        setLoading(false);
      })
      .catch(function (response) {
        setLoading(false);
      });
  }
  const onUserStoppedTyping = (sponID) => {
    setSponsorLoading(true);
    axios({
      method: "post",
      url: ApiPaths.checkUsername,
      data: {
        username: sponID,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        setCheckSponsorExist(response?.data);
        setLoading(false);
        setSponsorLoading(false);
      })
      .catch(function (response) {
        setLoading(false);
        setSponsorLoading(false);
      });
  };
  const handleInputChange = (e) => {
    setAiUserIdError("");
    const value = e.target.value;
    setAiUserId(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        if (value.length > 0) {
          onUserStoppedTyping(value);
        } else {
          setCheckSponsorExist([]);
        }
      }, 500)
    );
  };
  const es6Function = () => {
    FetchData();
    FetchPlanData();
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <section className="dashboard">
        <h1 className="textHeadingWithMargin mt-0 mt-4">Your Plan</h1>
        <div className="planDiv">
          <Row className="align-items-center">
            <Col md="4" className="mb">
              {/* <div className="planDivItems">
                <p>Daily income</p>
                <div>
                  <i>
                    <RiMoneyDollarCircleFill />
                  </i>
                  <h1>{planData?.daily_income == "" ? "0" : planData?.daily_income}</h1>
                </div>
              </div> */}
              <div
                className="addfundDiv inputPrimary "
                style={{ width: "100%", height: "100%" }}
              >
                <h1>Basic Joining Package </h1>
                <div className="subscriptionWallets">
                  <p>USDT Balance</p>
                  {acc == null ? (
                    <p style={{ color: "red" }}>Not Connected</p>
                  ) : (
                    <h5>$ {parseFloat(tokenBalance).toFixed(2)}</h5>
                  )}
                </div>
                <label htmlFor="Amount">User ID</label>
                {checkSponsorExist?.res == "success" ? (
                  <p id="sponsorVerified">{checkSponsorExist?.name}</p>
                ) : (
                  <p id="sponsorVerified" style={{ color: "red" }}>
                    {checkSponsorExist?.message}
                  </p>
                )}
                <div className="loginInput_inner">
                  <input
                    min={1}
                    required
                    type="text"
                    placeholder="Enter User ID"
                    value={aiUserId}
                    onChange={(e) => handleInputChange(e)}
                  />
                  {sponsorLoading ? <i id="sponsorLoading"></i> : null}
                </div>
                <p className="errorMsg">{aiUserIdError}</p>
                <label htmlFor="Amount">Select Package</label>
                <select
                  value={activationpackage}
                  onChange={(e) => setActivationPackage(e.target.value)}
                >
                  <option value="1">Select Basic Joining Package</option>
                  {packagesData?.data &&
                    packagesData?.data?.map((x) => {
                      return <option value={x.pin_rate}>{x.pin_type}</option>;
                    })}
                </select>
                <p className="errorMsg">{packageError}</p>
                {planData?.days_left > 0 ? (
                  <button
                    disabled={true}
                    id="disableBtn"
                    className="btnPrimary mt-3"
                  >
                    Already Activated
                  </button>
                ) : (
                  <button
                    disabled={false}
                    className="btnPrimary mt-3"
                    onClick={DepositPackage}
                  >
                    Proceed
                  </button>
                )}
              </div>
            </Col>
            <Col lg="8">
              <Row className="gy-lg-4">
                <Col md="6" className="mb">
                  <div className="planDivItems">
                    <p>Onikcoin allotted at joining</p>
                    <div>
                      <i>
                        <GiDeadEye />
                      </i>
                      <h1>
                        {parseFloat(
                          dashboardData?.subscription_token ?? 0
                        ).toFixed(2)}
                      </h1>
                    </div>
                  </div>
                </Col>
                <Col md="6" className="mb">
                  <div className="planDivItems">
                    <p>Staking Period left</p>
                    <div>
                      <i>
                        <RxLapTimer />
                      </i>
                      <h1>
                        {dashboardData?.effectiveDate && (
                          <Timer time={dashboardData?.effectiveDate} />
                        )}
                      </h1>
                    </div>
                  </div>
                </Col>
                <Col md="6" className="mb">
                  <div className="planDivItems">
                    <p>Active Plan</p>
                    <div>
                      <i>
                        <GiDeadEye />
                      </i>
                      <h1 style={{ fontSize: "16px" }}>
                        {planData?.plan_name == ""
                          ? "No Plan"
                          : planData?.plan_name}
                      </h1>
                    </div>
                  </div>
                </Col>
                <Col md="6" className="mb">
                  <div className="planDivItems">
                    <p>Total Staking</p>
                    <div>
                      <i>
                        <RxLapTimer />
                      </i>
                      <h1>${dashboardData?.package_amount}</h1>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Row className="d-flex gap-4"></Row>

        <h1 className="textHeadingWithMargin">Staking Packages</h1>
        <section className="upgradesCardSection">
          <Row className="mt-3" style={{ rowGap: "10px" }}>
            {planData?.package_data?.map((x, i) => {
              return (
                <Col md="3" className="mb">
                  <UpgradeCard
                    data={x}
                    fundWallet={parseFloat(
                      dashboardData?.wallets?.fund_wallet
                    ).toFixed(2)}
                    packageWallet={parseFloat(
                      dashboardData?.wallets?.package_wallet
                    ).toFixed(2)}
                    username={subsUserId}
                    es6Function={es6Function}
                    fun={FetchContractData}
                  />
                </Col>
              );
            })}
          </Row>
        </section>
      </section>
    </>
  );

  function resetAiSubError() {
    setPackageError("");
    setAiUserIdError("");
  }

  async function DepositPackage() {
    // await getAddress();
    // return 0;
    let x = 0;
    resetAiSubError();
    if (!aiUserId.length > 0) {
      setAiUserIdError("UserID cannot be empty");
    } else {
      if (checkSponsorExist?.res == "success") {
        x++;
      }
    }
    if (activationpackage == "1") {
      setPackageError("Please select package");
    } else {
      x++;
    }
    if (x === 2) {
      setLoading(true);

      const isEligible = await checkEligible(activationpackage);
      Data.isDebug && console.log("isEligible11", isEligible);

      if (isEligible == true) {
        let hash = await Topup(activationpackage);
        if (hash) {
          toastSuccess("Transaction Successfull");
          Data.isDebug && console.log("hash", hash);
          setLoading(false);
          axios({
            method: "post",
            url: ApiPaths.deposit,
            data: {
              amount: activationpackage,
              tx_hash: hash,
            },
            headers: {
              "Content-Type": "multipart/form-data",
              token: token,
            },
          })
            .then(function (response) {
              Data.isDebug && console.log("response", response);
              getBalance(acc);
              FetchPlanData();
              if (response?.data?.res == "error") {
                if (response?.data?.tokenStatus == "false") {
                  toastFailed("Token Expired Please Login");
                } else {
                  toastFailed(response?.data?.message);
                }
              } else {
                toastSuccess(response?.data?.message);
                FetchData();
              }
              setLoading(false);
            })
            .catch(function (response) {
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }
    async function checkEligible(amount) {
      try {
        const response = await axios.post(
          ApiPaths.activiationEligiblity,
          {
            amount: amount,
          },
          {
            headers: {
              "Content-Type": "application/json",
              token: token,
            },
          }
        );
        Data.isDebug && console.log("response", response);
        if (response?.data?.res == "success") {
          return true;
        } else {
          toastFailed(response?.data?.message);
          return false;
        }
      } catch (error) {
        Data.isDebug && console.error("Error:", error);
      }
    }

    async function Topup(amount) {
      let increaseDone = await IncreaseAllowance(amount);
      if (increaseDone == true) {
        const newAmount = BigInt(amount * 1e18);
        setLoading(true);
        try {
          const { ethereum } = window;
          if (ethereum) {
            let chain = await GetChainId();
            if (chain) {
              const provider = new ethers.providers.Web3Provider(ethereum);
              const signer = provider.getSigner();
              const busdInstance = new ethers.Contract(
                ContractDetails.Contract,
                ContractDetails.ContractABI,
                signer
              );
              let inc = await busdInstance.stake(
                newAmount,
                "0x50966810A133cDf7083BDE254954A8D61041d09B",
                365
              );
              const transactionData = await inc.wait();
              const hash = await transactionData.transactionHash;
              return hash;
            }
            setLoading(false);
          } else {
            setLoading(false);
          }
        } catch (error) {
          toastFailed("Transaction Failed");
          Data.isDebug && console.log("error", error);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }

    async function IncreaseAllowance(amount) {
      const newAmount = BigInt(amount * 1e18);
      setLoading(true);
      try {
        const { ethereum } = window;
        if (ethereum) {
          let chain = await GetChainId();
          if (chain) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            const busdInstance = new ethers.Contract(
              ContractDetails.BUSD,
              ContractDetails.BUSD_ABI,
              signer
            );
            let inc = await busdInstance.increaseAllowance(
              ContractDetails.Contract,
              newAmount
            );
            await inc.wait();
            return true;
          }
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        toastFailed("Transaction Failed");
        Data.isDebug && console.log("error", error);
        setLoading(false);
      }
    }
  }
};

export default Plans;
